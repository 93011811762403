import {
  PRODUCT__GIFT_CARD_PRODUCTS_FULFILLED,
  PRODUCT__LOAD_TO_EDIT_FULFILLED,
  PRODUCT__SEARCH_FULFILLED,
  PRODUCT__LOAD,
  PRODUCT__REMOVE_FAVORITE,
  PRODUCT__ADD_FAVORITE,
  APP__START_FULFILLED,
} from 'constants/actionTypes';
import {
  PRODUCT_VARIANT_GROUP_BULK_UPDATE,
  PRODUCT_VARIANT_GROUP_DELETE,
  PRODUCT_VARIANT_GROUP_PRODUCT_DELETE,
  PRODUCT_VARIANT_GROUP_PRODUCT_UPDATE,
  PRODUCT_VARIANT_GROUP_UPDATE,
} from '../actions';
import { SET_INTERCEPTED_REQUESTS } from 'features/intercepted-requests/action-creators';
import { ProductReducerInitialState } from './initial-state';
import {
  LOAD__PRO_STORE,
  PRO_STORE_SEARCH,
} from 'features/pro-stores/actions/action-types';
import {
  ROUTINE_UPDATE,
  ROUTINE_UPDATE_BULK,
} from 'features/routines/action-creators';
import {
  PRODUCT__ASK_MY_ESTIE_REQUEST,
  LOAD__PRODUCT_RULES,
  ADD__PRODUCTS_TO_BRAND_RULES,
  DELETE__PRODUCT_RULE,
  EDIT__PRODUCT_RULE,
  SAVE__PRODUCT_IMAGES,
  DELETE__PRODUCT_IMAGE,
  SAVE__DEFAULT_IMAGE,
  BULK__ADD_PRODUCT_IMAGES,
} from '../actions/constants';
import { LOAD_COLLECTIONS } from 'features/collections/actions/action-types';

export const ProductsReducer = (
  state = ProductReducerInitialState,
  { status, type, payload, requestPayload }
) => {
  switch (type) {
    case PRODUCT__SEARCH_FULFILLED: {
      const {
        data: {
          productSearch: { data = [], totals = {} },
        },
      } = payload;
      const productsById = data.reduce((acc, product) => {
        acc[product.id] = product;
        return acc;
      }, {});

      return {
        ...state,
        productSearchTotals: totals,
        productsById: {
          ...state.productsById,
          ...productsById,
        },
      };
    }

    case PRO_STORE_SEARCH: {
      if (status !== 'success') return state;

      const { data } = payload;

      const productsById = data.reduce((acc, product) => {
        acc[product.id] = product;
        return acc;
      }, {});

      return {
        ...state,
        productsById: {
          ...state.productsById,
          ...productsById,
        },
      };
    }

    case PRODUCT__GIFT_CARD_PRODUCTS_FULFILLED: {
      const {
        data: { giftCardProducts },
      } = payload;

      const productsById = giftCardProducts.reduce((acc, product) => {
        acc[product.id] = product;
        return acc;
      }, {});

      return {
        ...state,
        productsById: {
          ...state.productsById,
          ...productsById,
        },
      };
    }

    case SET_INTERCEPTED_REQUESTS: {
      if (!payload?.length) return state;
      const productsById = payload.reduce((acc, { response: { product } }) => {
        acc[product.id] = product;
        return acc;
      }, {});

      return {
        ...state,
        productsById: {
          ...state.productsById,
          ...productsById,
        },
      };
    }

    case LOAD__PRO_STORE: {
      if (!payload) return state;

      const storeProducts = payload?.data?.proStore?.products;
      const productsById = storeProducts?.reduce((acc, product) => {
        acc[product.id] = product;
        return acc;
      }, {});

      return {
        ...state,
        productsById: {
          ...state.productsById,
          ...productsById,
        },
      };
    }

    case PRODUCT__LOAD_TO_EDIT_FULFILLED: {
      if (!payload?.data?.product) return state;

      const { product } = payload.data;

      return {
        ...state,
        productsById: {
          ...state.productsById,
          [product.id]: product,
        },
      };
    }

    case PRODUCT__LOAD: {
      if (status !== 'success') return state;

      return {
        ...state,
        productsById: {
          ...state.productsById,
          [payload.id]: payload,
        },
      };
    }

    case PRODUCT_VARIANT_GROUP_BULK_UPDATE: {
      if (status !== 'success') return state;

      const updatedGroups = payload?.data?.reduce(
        (acc, group) => ({
          ...acc,
          [group?.id]: group,
        }),
        {}
      );

      return {
        ...state,
        productVariantGroupsById: {
          ...state.productVariantGroupsById,
          ...updatedGroups,
        },
      };
    }

    case PRODUCT_VARIANT_GROUP_UPDATE: {
      if (status !== 'success') return state;

      return {
        ...state,
        productVariantGroupsById: {
          ...state.productVariantGroupsById,
          [payload?.id]: payload,
        },
      };
    }

    case PRODUCT_VARIANT_GROUP_DELETE: {
      if (status !== 'success') return state;

      const { id } = requestPayload;
      const updatedGroups = { ...state.productVariantGroupsById };
      delete updatedGroups[id];

      return {
        ...state,
        productVariantGroupsById: updatedGroups,
      };
    }

    case PRODUCT_VARIANT_GROUP_PRODUCT_UPDATE: {
      if (status !== 'success') return state;

      const { productVariantGroupId } = payload;

      const group = state.productVariantGroupsById[productVariantGroupId];

      let existing = false;
      const updatedVariants = group?.variations?.map(productVariant => {
        if (productVariant.id === payload.id) {
          existing = true;
          return payload;
        }
        return productVariant;
      });

      if (!existing) updatedVariants.push(payload);

      return {
        ...state,
        productVariantGroupsById: {
          ...state.productVariantGroupsById,
          [productVariantGroupId]: {
            ...group,
            variations: updatedVariants,
          },
        },
      };
    }

    case PRODUCT_VARIANT_GROUP_PRODUCT_DELETE: {
      if (status !== 'success') return state;

      const { id: idToRemove } = requestPayload;

      const updatedGroups = { ...state.productVariantGroupsById };
      Object.keys(updatedGroups).forEach(key => {
        updatedGroups[key].variations = updatedGroups[key].variations.filter(
          ({ id }) => id !== idToRemove
        );
      });

      return {
        ...state,
        productVariantGroupsById: updatedGroups,
      };
    }

    case ROUTINE_UPDATE: {
      if (status !== 'success') return state;

      // Extract all products from a routine
      const productsById =
        payload?.steps
          // Get all recommendations from all steps
          ?.reduce(
            (acc, { recommendations }) => [...acc, ...recommendations],
            []
          )
          // Turn each recommendation into with a product into a map of the products
          ?.reduce((acc, { product = null }) => {
            if (!product) return acc;

            return {
              ...acc,
              [product.id]: product,
            };
          }, {}) ?? {};

      return {
        ...state,
        productsById: {
          ...state.productsById,
          ...productsById,
        },
      };
    }

    case ROUTINE_UPDATE_BULK: {
      if (status !== 'success') return state;

      // Extract all products from a routine
      const productsById =
        payload
          // Get a list of all steps
          ?.reduce((acc, { steps }) => [...acc, ...steps], [])
          // Get a list of all recommendations
          ?.reduce(
            (acc, { recommendations }) => [...acc, ...recommendations],
            []
          )
          // Turn each recommendation into with a product into a map of the products
          ?.reduce((acc, { product = null }) => {
            if (!product) return acc;

            return {
              ...acc,
              [product.id]: product,
            };
          }, {}) ?? {};

      return {
        ...state,
        productsById: {
          ...state.productsById,
          ...productsById,
        },
      };
    }

    case PRODUCT__ADD_FAVORITE: {
      const { productId } = requestPayload;
      if (status !== 'success') return state;

      return {
        ...state,
        productsById: {
          ...state.productsById,
          [productId]: {
            ...state.productsById[productId],
            favorite: true,
          },
        },
      };
    }

    case PRODUCT__REMOVE_FAVORITE: {
      const { productId } = requestPayload;
      if (status !== 'success') return state;

      return {
        ...state,
        productsById: {
          ...state.productsById,
          [productId]: {
            ...state.productsById[productId],
            favorite: false,
          },
        },
      };
    }

    case PRODUCT__ASK_MY_ESTIE_REQUEST: {
      if (status !== 'success') return state;

      return {
        ...state,
        productsById: {
          ...state.productsById,
          [requestPayload.productId]: {
            ...state.productsById[requestPayload.productId],
            askedEstie: true,
          },
        },
      };
    }

    case LOAD__PRODUCT_RULES: {
      if (status !== 'success') return state;

      const productsById = payload.reduce((acc, product) => {
        acc[product.id] = product;
        return acc;
      }, {});

      return {
        ...state,
        productRulesById: {
          ...productsById,
        },
      };
    }

    case ADD__PRODUCTS_TO_BRAND_RULES: {
      if (status !== 'success') return state;

      const productsById = payload.reduce((acc, product) => {
        acc[product.id] = product;
        return acc;
      }, {});

      return {
        ...state,
        productRulesById: {
          ...state.productRulesById,
          ...productsById,
        },
      };
    }

    case DELETE__PRODUCT_RULE: {
      if (status !== 'success') return state;

      const productsById = state.productRulesById;
      delete productsById[requestPayload.productId];

      return {
        ...state,
        productRulesById: {
          ...productsById,
        },
      };
    }

    case EDIT__PRODUCT_RULE: {
      if (status !== 'success') return state;

      return {
        ...state,
        productRulesById: {
          ...state.productRulesById,
          [payload.id]: {
            ...payload,
          },
        },
      };
    }

    case LOAD_COLLECTIONS: {
      if (status !== 'success') return state;

      const featured = payload.reduce(
        (acc, collection) => [...acc, ...collection.featured],
        []
      );
      const products = featured.reduce(
        (acc, feature) => [...acc, ...feature.products],
        []
      );

      const productsById = products.reduce((acc, product) => {
        acc[product.id] = product;
        return acc;
      }, {});

      return {
        ...state,
        productsById: {
          ...state.productsById,
          ...productsById,
        },
      };
    }

    case SAVE__PRODUCT_IMAGES: {
      if (status !== 'success') return state;

      return {
        ...state,
        productsById: {
          ...state.productsById,
          [payload.id]: {
            ...state.productsById[payload.id],
            images: payload.images,
          },
        },
      };
    }

    case SAVE__DEFAULT_IMAGE: {
      if (status !== 'success') return state;

      return {
        ...state,
        productsById: {
          ...state.productsById,
          [payload.id]: {
            ...payload,
          },
        },
      };
    }

    case DELETE__PRODUCT_IMAGE: {
      if (status !== 'success') return state;

      return {
        ...state,
        productsById: {
          ...state.productsById,
          [requestPayload.productId]: {
            ...payload,
          },
        },
      };
    }

    case BULK__ADD_PRODUCT_IMAGES: {
      if (status !== 'success') return state;

      const updated = { ...state.productsById };

      payload.forEach(res => {
        const { id, productImages = [] } = res;
        if (updated[id]) {
          updated[id].images = productImages;
        }
      });

      return {
        ...state,
        productsById: {
          ...updated,
        },
      };
    }

    case APP__START_FULFILLED: {
      if (!payload?.data?.previouslyOrderedProducts) return state;

      const { previouslyOrderedProducts = [] } = payload.data;

      return {
        ...state,
        previouslyOrderedProducts,
      };
    }

    default:
      return state;
  }
};

export default ProductsReducer;
