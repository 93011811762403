import React, { useRef, useEffect } from 'react';
import proptypes from 'prop-types';
import { Box, CardHeader } from '@mui/material';

/**
 * @typedef {Object} AppContainerProps
 * @property {Object} [containerProps] - The container props.
 * @property {Object} [containerSx] - The sx style prop for container.
 * @property {Object} [titleProps] - The title props.
 * @property {string | ReactElement} [title] - The title for the component.
 * @property {func} [actions] - The actions prop.
 * @property {string} [subheader] - The subheader for the component.
 * @property {Boolean} [compact] - Boolean flag to remove padding.
 * @property {Object} [children] - Children passed to the component
 * @property {Object} [cardHeaderSx] - The sx style prop for the carheader.
 * @property {Object} [actionsSx] - The sx style prop for actions.
 */

/**
 * AppContainer functional component.
 * @param {AppContainerProps} props - The props object.
 * @returns {JSX.Element} JSX representation of the component.
 */

export const AppContainer = ({
  containerProps = {},
  containerSx = {},
  titleProps = {},
  title = null,
  actions = null,
  subheader = null,
  compact = false,
  children,
  cardHeaderSx = {},
  actionsSx = {},
}) => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current) ref.current.scrollIntoView();
  }, []);

  return (
    <Box
      ref={ref}
      sx={{
        margin: '0 auto',
        ...(compact
          ? {
              p: 0,
            }
          : {
              backgroundColor: 'background.default',
              px: [1.5, 3],
              minHeight: 'var(--app-height)',
            }),
        ...containerSx,
      }}
      {...containerProps}
    >
      <If condition={title || actions}>
        <CardHeader
          title={title}
          subheader={subheader}
          id="app-container-header"
          sx={{
            py: compact ? 1 : 2,
            px: 2,
            display: 'flex',
            flexWrap: 'wrap',
            '& .MuiCardHeader-action': {
              display: 'inline-flex',
              flexGrow: 1,
              justifyContent: 'flex-end',
              height: 1,
              alignSelf: 'center',
              m: 1,
              '& > *': {
                alignSelf: 'center',
              },
              ...actionsSx,
            },
            ...cardHeaderSx,
          }}
          titleTypographyProps={{
            variant: 'h4',
            sx: {
              my: 1,
              ...(titleProps.sx || {}),
            },
            ...titleProps,
          }}
          action={actions}
        />
      </If>
      {children}
    </Box>
  );
};

AppContainer.propTypes = {
  containerProps: proptypes.object,
  containerSx: proptypes.object,
  titleProps: proptypes.object,
  title: proptypes.any,
  subheader: proptypes.any,
  actions: proptypes.any,
  children: proptypes.any,
  compact: proptypes.bool,
  cardHeaderSx: proptypes.object,
  actionsSx: proptypes.object,
};
