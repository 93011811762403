import React from 'react';
import { Alert, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { TIERS } from '@pomp-libs/core';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

export const noCommissionTextAlert = `You're missing out on client retail sales and unable to earn additional revenue. Activate your account now.`;

export const NoTierCommissionAlert = ({ user }) => {
  const { pathname } = useLocation();
  const hideAlert = ['/dashboard', '/pomp-pro', '/profile/subscriptions'];
  const onBoarding = pathname.includes('/onboarding');

  if (
    !user ||
    user.get('tier') !== TIERS.NONE ||
    hideAlert.includes(pathname) ||
    onBoarding
  )
    return null;

  return (
    <Alert
      severity="error"
      icon={false}
      action={
        <Button
          color="inherit"
          size="small"
          sx={{ textAlign: 'center' }}
          component={Link}
          to="/profile/subscriptions"
        >
          Elevate Now!
        </Button>
      }
    >
      {noCommissionTextAlert}
    </Alert>
  );
};

NoTierCommissionAlert.propTypes = {
  user: PropTypes.object,
};
