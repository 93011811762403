import React, { lazy, Suspense, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { SCROLLABLE_CONTENT_ID } from 'constants/ids';
import { useAuth } from 'features/auth';
import styles from './styles.scss'; // eslint-disable-line @typescript-eslint/no-unused-vars
import { useSideBarAwareTheme } from '../../providers';
import { AppLoader } from '../App';
import { ScrollToTopFab } from './scroll-to-top-button';
import { NoTierCommissionAlert } from './no-tier-commission-alert';

const SideNav = lazy(
  () => import(/* webpackChunkName: "Navigation" */ './navigation')
);

const MessageEsthetician = lazy(() =>
  import(
    /* webpackChunkName: "MessageEsthetician" */ './MessageEstheticianChatButton/message-esthetician'
  ).then(({ MessageEsthetician }) => ({
    default: MessageEsthetician,
  }))
);

const Layout = ({ children }) => {
  const { pathname } = useLocation();
  const { user, isEsthetician } = useAuth();
  const { mobileView, hideSideNav, margins } = useSideBarAwareTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Box
      sx={{
        minHeight: 'var(--app-height)',
        backgroundColor: 'background.paper',
      }}
    >
      <If condition={!hideSideNav}>
        <SideNav />
      </If>
      <Suspense fallback={<AppLoader />}>
        <If condition={user?.get('role') === 'customer' && !mobileView}>
          <MessageEsthetician />
        </If>
        <Box
          id={SCROLLABLE_CONTENT_ID}
          sx={{
            height: 'var(--app-height)',
            overflow: 'scroll',
            ...margins,
          }}
        >
          <If condition={isEsthetician}>
            <NoTierCommissionAlert user={user} />
          </If>
          {children}
        </Box>
        <ScrollToTopFab />
      </Suspense>
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
};

export default Layout;
