import {
  USERS__ADMIN_UPDATE_PASSWORD,
  USERS__CHANGE_ROLE,
  USERS__ESTHETICIAN_UPSERT_CLIENT_SHIPPING_ADDRESS,
  USERS__LOAD_ALL,
  USERS__LOAD_ESTHETICIANS,
  USERS__LOAD_NEEDS_CONTACT,
  USERS__LOAD_ONE,
  USERS__PASSWORD_RESET,
  USERS__SEND_RESET_PASSWORD,
  USERS__SEARCH,
  USERS__SELECT,
  USERS__SET_NEEDS_CONTACT,
  USERS__VALIDATE_EMAIL,
  USERS__UPDATE_PROFILE,
  USERS__CHANGE_TIER,
  USERS__UPDATE_GIFTCARD_BALANCE,
  USERS__GET_SETUP_CLIENT_SECRET,
  USERS_BASIC__LOAD_ONE,
  USERS__ADJUST_CREDIT,
  USERS__SUBSCRIPTION_CREDIT,
  USERS__SUBMIT_ONBOARDING_DETAILS,
  USERS__SEARCH_SELECTOR,
  USERS__ADD_METADATA,
  USERS__LOAD_SURVEY_USER,
} from './action-types';
import {
  AUTH_USER__GET_METADATA,
  REFERRALS__UPDATE_PRO_REFERRER,
} from 'constants/actionTypes';
import graphql from 'lib/api/graphql';
import userChangeRole from '../graphql/mutations/user-change-role.graphql';
import userChangeTier from '../graphql/mutations/user-change-tier.graphql';
import userLoad from '../graphql/queries/user-load.graphql';
import userLoadSurvey from '../graphql/queries/user-load-survey.graphql';
import updateUser from '../graphql/mutations/update.graphql';
import userNeedsContact from '../graphql/queries/user-needs-contact.graphql';
import usersAll from '../graphql/queries/users-all.graphql';
import usersAllBasic from '../graphql/queries/users-all-basic.graphql';
import userSearch from '../graphql/queries/user-search.graphql';
import emailValidate from '../graphql/queries/email-validate.graphql';
import sendResetPassword from '../graphql/mutations/send-reset-password.graphql';
import userPasswordUpdate from '../graphql/mutations/user-password-update.graphql';
import loadEstheticians from '../graphql/queries/load-estheticians.graphql';
import updateEsthetician from '../graphql/mutations/update-esthetician.graphql';
import getMetadata from '../graphql/queries/get-metadata.graphql';
import updateProReferrer from '../graphql/mutations/update-pro-referrer.graphql';
import updateUserDetails from '../graphql/mutations/admin-update-user-details.graphql';
import updateUserPassword from '../graphql/mutations/admin-update-user-password.graphql';
import updateCustomerProfile from '../graphql/mutations/update-profile.graphql';
import updateCustomerPassword from '../graphql/mutations/update-customer-password.graphql';
import updateUserStatus from '../graphql/mutations/update-user-status.graphql';
import upsertAddress from 'graphQL/mutations/address.graphql';
import inviteUsers from '../graphql/mutations/invite-users.graphql';
import resendInvite from '../graphql/mutations/resend-invite.graphql';
import getInvitedUserInformation from '../graphql/mutations/get-invited-user-information.graphql';
import updateGiftCardBalance from '../graphql/mutations/update-giftcard-balance.graphql';
import { generateAsyncAction } from 'lib/action-generators';
import { graphqlSingletonWrapper } from 'lib/api/graphql-singleton-wrapper';
import addUserMetadataProperty from '../graphql/mutations/add-user-metadata-property.graphql';
import getSetupClientSecret from '../graphql/queries/get-setup-client-secret.graphql';
import getEstheticianDisplayName from '../graphql/queries/get-esthetician-display-name.graphql';
import adjustCredits from '../graphql/mutations/adjust-subscription-credits';
import subscriptionCredits from '../graphql/queries/subscription-credits';
import submitOnboardingDetails from '../graphql/mutations/submit-onboarding-details.graphql';
import userSearchSelector from '../graphql/queries/user-search-selector.graphql';
import changeUserType from '../graphql/mutations/user-change-type.graphql';
import userSearchTotals from '../graphql/queries/user-search-totals.graphql';

export default {
  changeRole: (userId, role) =>
    generateAsyncAction(
      USERS__CHANGE_ROLE,
      { userId, role },
      graphql(userChangeRole, { userId, role })
    ),

  loadSingleUser: userId => {
    if (!userId) throw new Error('loadSingleUser requires a userId');
    return generateAsyncAction(
      USERS__LOAD_ONE,
      { user: userId },
      graphql(userLoad, { user: userId }),
      'user'
    );
  },

  loadSurveyUser: userId => {
    if (!userId) throw new Error('loading user requires a userId');
    return generateAsyncAction(
      USERS__LOAD_SURVEY_USER,
      { user: userId },
      graphql(userLoadSurvey, { userId }),
      'surveyUser'
    );
  },

  loadNeedsContact: () => ({
    type: USERS__LOAD_NEEDS_CONTACT,
    payload: graphql(userNeedsContact),
  }),

  loadAll: () => ({
    type: USERS__LOAD_ALL,
    payload: graphql(usersAll),
  }),

  loadAllBasic: ({ role } = {}) => ({
    type: USERS__LOAD_ALL,
    payload: graphql(usersAllBasic, { role }),
  }),

  needsContact: userId => ({
    type: USERS__SET_NEEDS_CONTACT,
    payload: graphql(updateUser, {
      user: {
        id: userId,
        needsContact: false,
      },
    }),
    meta: { userId },
  }),

  search: searchData =>
    generateAsyncAction(
      USERS__SEARCH,
      searchData,
      graphqlSingletonWrapper.register('user-search')(userSearch, searchData),
      'userSearch'
    ),

  select: userId => ({
    type: USERS__SELECT,
    payload: { user: userId },
  }),

  validateEmail: email => ({
    type: USERS__VALIDATE_EMAIL,
    payload: graphql(emailValidate, { email }),
  }),

  resetPassword: (oldPassword, password, resetKey, email) => ({
    type: USERS__PASSWORD_RESET,
    payload: graphql(userPasswordUpdate, {
      user: { oldPassword, password, resetKey, email },
    }),
  }),

  sendResetPassword: email => ({
    type: USERS__SEND_RESET_PASSWORD,
    payload: graphql(sendResetPassword, { email }),
  }),

  loadEstheticians: () =>
    generateAsyncAction(
      USERS__LOAD_ESTHETICIANS,
      {},
      graphql(loadEstheticians)
    ),

  updateEsthetician: (userId, estheticianId, findAnEstie = false) =>
    generateAsyncAction(
      USERS__LOAD_ONE,
      { userId, estheticianId, findAnEstie },
      graphql(updateEsthetician, { userId, estheticianId, findAnEstie }),
      'updateEsthetician'
    ),

  getMetadata: url => dispatch =>
    dispatch({
      type: AUTH_USER__GET_METADATA,
      payload: graphql(getMetadata, { link: url }),
    }),

  updateProReferrer: ({ userIdReferred, userIdReferrer }) => ({
    type: REFERRALS__UPDATE_PRO_REFERRER,
    payload: graphql(updateProReferrer, { userIdReferred, userIdReferrer }),
  }),

  adminEditUserProfile: ({ id, updated }) =>
    generateAsyncAction(
      USERS__LOAD_ONE,
      { id, updated },
      graphql(updateUserDetails, { id, updated }),
      'adminUpdateUserDetails'
    ),

  adminUpdatePassword: ({ id, password }) =>
    generateAsyncAction(
      USERS__ADMIN_UPDATE_PASSWORD,
      { id, password },
      graphql(updateUserPassword, { id, password })
    ),

  updateCustomerProfile: updated =>
    generateAsyncAction(
      USERS__UPDATE_PROFILE,
      { updated },
      graphql(updateCustomerProfile, { updated }),
      'updateCustomerProfile'
    ),

  updateCustomerPassword: (current, updated) =>
    generateAsyncAction(
      USERS__LOAD_ONE,
      { current, updated },
      graphql(updateCustomerPassword, { current, updated })
    ),

  estheticianUpsertClientShippingAddress: (addressId, updated) =>
    generateAsyncAction(
      USERS__ESTHETICIAN_UPSERT_CLIENT_SHIPPING_ADDRESS,
      { addressId, updated },
      graphql(upsertAddress, { address: { id: addressId, ...updated } })
    ),

  updateStatus: (id, status) =>
    generateAsyncAction(
      USERS__LOAD_ONE,
      { id, status },
      graphql(updateUserStatus, { id, status }),
      'updateUserStatus'
    ),

  inviteUsers: users =>
    generateAsyncAction(
      '',
      { users },
      graphql(inviteUsers, { users }),
      'inviteUsers'
    ),

  getInvitedUserInformation: id =>
    generateAsyncAction(
      USERS__LOAD_ONE,
      { id },
      graphql(getInvitedUserInformation, { id }),
      'getInvitedUserInformation'
    ),

  resendInvite: id =>
    generateAsyncAction(
      '',
      { id },
      graphql(resendInvite, { id }),
      'resendInvite'
    ),

  changeTier: (id, tier) =>
    generateAsyncAction(
      USERS__CHANGE_TIER,
      { id, tier },
      graphql(userChangeTier, { id, tier }),
      'changeTier'
    ),

  updateGiftCardBalance: giftCard =>
    generateAsyncAction(
      USERS__UPDATE_GIFTCARD_BALANCE,
      { giftCard },
      graphql(updateGiftCardBalance, { giftCard })
    ),

  addUserMetadataProperty: (id, property) =>
    generateAsyncAction(
      USERS__ADD_METADATA,
      { id, property },
      graphql(addUserMetadataProperty, { id, property }),
      'addUserMetadataProperty'
    ),

  getSetupIntentClientSecret:
    (stripeId, mode = 'setup', order = null) =>
    dispatch =>
      dispatch({
        type: USERS__GET_SETUP_CLIENT_SECRET,
        payload: graphql(getSetupClientSecret, { stripeId, mode, order }),
      }),

  getEstheticianDisplayName: id =>
    generateAsyncAction(
      USERS_BASIC__LOAD_ONE,
      { id },
      graphql(getEstheticianDisplayName, { id }),
      'getEstheticianDisplayName'
    ),

  adjustCredits: (credit, userId, type) =>
    generateAsyncAction(
      USERS__ADJUST_CREDIT,
      { credit, userId, type },
      graphql(adjustCredits, { credit, userId, type }),
      'adjustCredits'
    ),

  getSubscriptionCredits: userId =>
    generateAsyncAction(
      USERS__SUBSCRIPTION_CREDIT,
      { userId },
      graphql(subscriptionCredits, { userId }),
      'subscriptionCredits'
    ),

  submitOnboardingDetails: details =>
    generateAsyncAction(
      USERS__SUBMIT_ONBOARDING_DETAILS,
      { details },
      graphql(submitOnboardingDetails, { details }),
      'submitOnboardingDetails'
    ),

  userSearchSelector: () =>
    generateAsyncAction(
      USERS__SEARCH_SELECTOR,
      {},
      graphql(userSearchSelector),
      'userSearchSelector'
    ),

  changeUserType: (id, type) =>
    generateAsyncAction(
      USERS__LOAD_ONE,
      { id, type },
      graphql(changeUserType, { id, type }),
      'changeUserType'
    ),

  userSearchTotals: () =>
    generateAsyncAction(
      '', // TODO: Add this if we want to store these in the reducers
      {},
      graphql(userSearchTotals, {}),
      'userSearchTotals'
    ),
};

export * from './action-types';
