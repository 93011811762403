import { generateAsyncAction } from 'lib/action-generators';
import {
  SUBSCRIPTION_CANCEL,
  SUBSCRIPTION_CREATE,
  SUBSCRIPTION_UPDATE,
} from './constants';
import graphql from 'lib/api/graphql';
import modifySubscription from '../graphql/mutations/modify-subscription';
import cancelSubscription from '../graphql/mutations/cancel-subscription';
import adminForceCancelSubscription from '../graphql/mutations/admin-force-cancel-subscription';
import createSubscription from '../graphql/mutations/create-subscription';
import verifyCoupon from '../graphql/mutations/verify-coupon';
import { SUBSCRIPTION_INTERVALS } from '@pomp-libs/core';

export const BillingActions = {
  modifySubscription: (
    tier,
    billingPeriod = SUBSCRIPTION_INTERVALS.MONTH,
    couponCode = '',
    setupIntent = ''
  ) =>
    generateAsyncAction(
      SUBSCRIPTION_UPDATE,
      { tier, billingPeriod, couponCode },
      graphql(modifySubscription, {
        tier,
        setupIntent,
        billingPeriod,
        couponCode,
      }),
      'modifySubscription'
    ),

  cancelSubscription: subscriptionId =>
    generateAsyncAction(
      SUBSCRIPTION_CANCEL,
      { subscriptionId },
      graphql(cancelSubscription, { subscriptionId }),
      'cancelSubscription'
    ),

  adminForceCancelSubscription: subscriptionId =>
    generateAsyncAction(
      SUBSCRIPTION_CANCEL,
      { subscriptionId },
      graphql(adminForceCancelSubscription, { subscriptionId }),
      'adminForceCancelSubscription'
    ),

  createSubscription: subscriptionInterval =>
    generateAsyncAction(
      SUBSCRIPTION_CREATE,
      { subscriptionInterval },
      graphql(createSubscription, { subscriptionInterval }),
      'createSubscription'
    ),

  verifyCoupon: (tier, billingPeriod, couponCode) =>
    generateAsyncAction(
      '',
      { tier, billingPeriod, couponCode },
      graphql(verifyCoupon, { tier, billingPeriod, couponCode }),
      'verifyCoupon'
    ),
};
