import { lazy } from 'react';
import { FEATURE_FLAGS } from '@pomp-libs/core';
import referAndEarn from 'images/png/Refer&Earn.png';

const Account = lazy(
  () => import(/* webpackChunkName: "Account" */ 'components/pages/Account')
);
const Welcome = lazy(
  () => import(/* webpackChunkName: "Welcome" */ 'components/pages/Welcome')
);
const ProfilePage = lazy(() =>
  import(/* webpackChunkName: "ProfilePage" */ 'components/pages/profile').then(
    ({ ProfilePage }) => ({
      default: ProfilePage,
    })
  )
);
const ReferAndEarn = lazy(
  () =>
    import(
      /* webpackChunkName: "ReferAndEarn" */ 'components/pages/ReferAndEarn'
    )
);
const ResetPassword = lazy(
  () =>
    import(
      /* webpackChunkName: "ResetPassword" */ 'components/pages/ResetPassword'
    )
);
const ShareRedirector = lazy(() =>
  import(
    /* webpackChunkName: "ShareRedirector" */ 'components/pages/share'
  ).then(({ ShareRedirector }) => ({
    default: ShareRedirector,
  }))
);

const HomeRedirector = lazy(
  /* webpackChunkName: "IntegrationsPage" */ () =>
    import('components/pages/home').then(({ HomeRedirector }) => ({
      default: HomeRedirector,
    }))
);

export const AccountRoutes = [
  {
    component: HomeRedirector,
    isExact: true,
    key: 'base',
    path: '/',
  },
  {
    component: Welcome,
    isExact: true,
    key: 'welcome',
    path: '/welcome',
  },
  {
    component: Account,
    isExact: true,
    key: 'account',
    path: '/account',
    flag: FEATURE_FLAGS.ORDER_HISTORY,
  },
  {
    component: ResetPassword,
    isExact: true,
    key: 'reset-password',
    path: '/reset-password',
    flag: FEATURE_FLAGS.MANAGE_ACCOUNT,
  },
  {
    component: ProfilePage,
    key: 'profile-home',
    path: '/profile',
    flag: FEATURE_FLAGS.MANAGE_ACCOUNT,
  },
  {
    component: ReferAndEarn,
    isExact: true,
    key: 'refer-and-earn',
    path: '/refer-and-earn',
    flag: FEATURE_FLAGS.REFER_AND_EARN,
    guestPageProps: {
      title: 'Refer a Friend',
      img: referAndEarn,
      subtitle:
        'Sign up to refer friends to Pomp and get $25 in store credit. Refer up to 5 friends to receive up to $125 in credit.',
      alt: 'A stamp card.',
    },
  },
  {
    component: ShareRedirector,
    isExact: true,
    key: 'share',
    path: '/share',
    flag: FEATURE_FLAGS.REFER_AND_EARN,
  },
];
