import Immutable from 'immutable';

export default Immutable.fromJS({
  isLoading: false,
  orders: [],
  ordersById: {},
  estheticians: [],
  added: false,
  showAll: false,
  type: '',
  filter: null,
  ordersByUserId: {},
  failedTransactionsToggle: false,
  groupedOrderShipments: [],
});
