export const groupOrderByShipment = data =>
  data.orders.map(order => {
    const trackingGroups = {};
    const unshippedProducts = [];

    order.shipments.forEach(({ carrier, trackingNumber, status, products }) => {
      if (status) {
        trackingGroups[trackingNumber] ||= {
          trackingNumber,
          carrier,
          status,
          created: order.created,
          products: [],
        };
        products.forEach(({ orderProduct }) => {
          const product = order.products.find(
            p => p.product.id === orderProduct.product.id
          );
          if (product)
            trackingGroups[trackingNumber].products.push({
              ...product.product,
              quantity: product.quantity,
              giftcardMetadata: product.giftcardMetadata,
            });
        });
      }
    });

    // Identify unshipped products
    unshippedProducts.push(
      ...order.products
        .filter(
          ({ product }) =>
            !order.shipments.some(
              ({ status, products }) =>
                (status === 'shipped' || status === 'back_ordered') &&
                products.some(
                  ({ orderProduct }) => orderProduct.product.id === product.id
                )
            )
        )
        .map(({ quantity, product, giftcardMetadata }) => ({
          trackingNumber: null,
          created: order.created,
          products: [{ ...product, quantity, giftcardMetadata }],
        }))
    );

    return {
      ...order,
      orderGroups: [...Object.values(trackingGroups), ...unshippedProducts],
    };
  });
